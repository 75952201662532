import React from "react"
import styled from "styled-components"

import BaseImage, { BaseContainer, Props } from "../../components/Section/Image"
import breakpoints from "../../constants/breakpoints"

const Container = styled(BaseContainer)`
  display: none;

  ${breakpoints("xl")`
    display: block;
  `}
`

export default function Image(props: Props) {
  return <BaseImage {...props} Container={Container} />
}
