import React from "react"
import styled from "styled-components"

import BaseFeatures, {
  BaseList,
  Props,
} from "../../../../components/Section/Body/IconsList"
import { iconFlexBreakpoint } from "../../../../components/Section/Body/IconsList/styles"
import Feature from "./Feature"

const List = styled(BaseList)`
  ${iconFlexBreakpoint`
    margin-top: 2rem;
  `}
`

export default function Features(props: Props) {
  return <BaseFeatures {...props} List={List} IconItem={Feature}></BaseFeatures>
}
