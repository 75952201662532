import React from "react"

import Layout from "../components/layout"
import Service from "../types/api/Service"
import Seo from "../components/seo"
import HeroWithButton from "../components/HeroWithButton"
import Sections from "../components/Sections"
import Features from "./Features"
import CallToAction from "../components/CallToAction"

interface Query {
  strapiServices: Service
}

interface Props {
  data: Query
}

export default function ServicePage({
  data: {
    strapiServices: { hero, features, sections },
  },
}: Props) {
  return (
    <Layout>
      <Seo title={hero.title} />
      <HeroWithButton {...hero} />
      <Sections sections={sections} />
      <Features features={features} />
      <CallToAction />
    </Layout>
  )
}
