import React from "react"
import styled from "styled-components"

import BaseIconItem, {
  BaseListItem,
  Props,
} from "../../../../components/Section/Body/IconsList/IconItem"
import breakpoints from "../../../../constants/breakpoints"
import { getFlexWidth } from "../../../../utils/styles"

const flexWidth = getFlexWidth(2, "3.3125rem")

const ListItem = styled(BaseListItem)`
  ${breakpoints("md")`
    width: ${flexWidth};
    flex-basis: ${flexWidth};
  `}
`

export default function Feature(props: Props) {
  return <BaseIconItem {...props} ListItem={ListItem} />
}
