import React from "react"

import Service from "../../types/api/Service"
import Section from "../../components/Section"
import Image from "./Image"
import Body from "./Body"

interface Props {
  features: Service["features"]
}

export default function Features({ features }: Props) {
  return (
    <Section Body={Body} imageDirection="left" Image={Image} {...features} />
  )
}
